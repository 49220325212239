<template>
  <div class="contents">
    <!-- 세무대리 관리번호 -->
    <base-table-contents-agent
        :caption="tableData_attr.caption"
        :heads="tableData_attr.heads"
        :items="tableData_attr.items"
        :totalErrorMsg="tableData_attr.totalErrorMsg"
        :invalidCnt="invalidCnt"
        :unvalidCnt="unvalidCnt"
        @toParent:visible1="val => visible1 = val"
        @toParent:deleteCurrRow="val => deleteRow(val)"
        @toParent:updateManageNo="openIdPwPopup('manageNo')"
        @toParent:updateMemberId="openIdPwPopup('memberId')"
        @toParent:updateCert="loadDoc('setup', null)">

      <base-card-header slot="slot_card_header"
                        :headerTitle="headerTitle" />

    </base-table-contents-agent>
    <div class="w3-container">
      <base-login-id-pwd-popup :visible="visible1"
                               @toParent:visible="val => visible1 = val"
                               @toParent:submit="(type, val1, val2) => submitForm(type, val1, val2)"
                               :propPopupType="parentPopupType"
                               :propId="parentId"
                               :propPwd="parentPwd"
      />
    </div>
    <div class="btnwrap">
      <button type="button" @click="verifyAuth" class="btn btn-block btn-primary btn-lg w200">인증정보 검증</button>
    </div>
  </div>
</template>
<script>
export default {
	name: 'PageAuthHometaxAgent',
	data: () => ({ 
		prefix : "api/auth/hometax",

		// init Variable
		userManageNo : "",

		// Auth Table Components attrs
		headerTitle: "세무대리 인증 정보 관리",
		tableData_attr: {
			caption: '세무대리 인증 정보관리 표',
			heads: [
				{col: '', style: 'width:180px'},
				{col: '인증구분', style: 'width:110px'},
				{col: '등록상태', style: ''},
				{col: '인증정보 (인증서명/회원ID)', style: 'width:90px'},				
				{col: '등록일', style: 'width:110px'},
				{col: '관리', style: 'width:140px'},
			],
			items: [],
			
			totalErrorMsg: {},
		},
		invalidCnt: 0,
		unvalidCnt: 0,

		// Id/Pw Popup attrs
		visible1: false,
		parentId : "",
		parentPwd: "",
		parentPopupType: "",

	}),
	methods: {
		initInfo(){
			//화면 최초 접근시 데이터를 가져온다.
			let that = this;
			this.$cAxios.post(this.prefix + "/agent/init/inquiry")
			.then((res)=>{
				that.userManageNo = res.data.taxManageNo;
			}).catch((err)=>{
				console.log(err.response);
			}).finally(()=>{
				this.authInquiry();
			});
		},
		authInquiry(){
			var that = this;
			this.$cAxios.post(this.prefix + "/agent/auth/inquiry")
			.then((res)=>{
				// let valificationFlag = false;
				that.invalidCnt = 0 ;
				that.unvalidCnt = 0 ;
				let errorMsg = "";

				let tableData = new Array();
				for(var i = 0 ; i< res.data.length; i++){
					if(res.data[i] != null){
						var resData = {
							idx : res.data[i].idx,
							subject: res.data[i].subject,
							tds:{
								type: res.data[i].type,
								status: res.data[i].status,
								name: res.data[i].name,
								regDt: res.data[i].regDt
							}
						}; 
						
						tableData[i] = resData;
						if(res.data[i].status == "INVALID"){
							that.invalidCnt ++;
							errorMsg = res.data[i].statusDesc;
						} else if(res.data[i].status == null 
							|| res.data[i].status == ''
							|| res.data[i].status == "UNVALID"){
							that.unvalidCnt ++;
						}
					}
				}
				if(that.invalidCnt == 3){
					that.$set(that.tableData_attr, "totalErrorMsg", {target: '', status_desc: errorMsg});
				}

				that.$set(that.tableData_attr, "items", tableData);
			})
			.catch(()=>{
				alert("인증정보 조회중 오류가 발생했습니다.");
			});
		},
		deleteRow(idx){
			let that = this;
			let url = "";
			let delMsg = "";
			let delYn = confirm("인증정보를 삭제하시겠습니까?");
			if(!delYn) return false;

			if(idx == "0"){
				//인증서
				url += "/agent/cert/remove";
				delMsg += "인증서";
			}else if(idx == "1"){
				//관리번호
				url += "/agent/manageNo/remove";
				delMsg += "세무대리인 관리번호"
			}else if(idx == "2"){
				//회원정보
				url += "/agent/memberId/remove";
				delMsg += "회원"
			}else{
				return false;
			}

			let delYN = confirm(delMsg + " 정보를 삭제하시겠습니까?");
			if(delYN){
				this.$cAxios.post(this.prefix + url)
				.then(()=>{
					//삭제 후 조회한 인증서 정보가 null이면 정상적으로 삭제되었다.
					that.authInquiry();
				})
				.catch((err)=>{
					if(err.response){
						alert(err.response.data.message);
					}
				});
			}else{
				return false;
			}
		},
		openIdPwPopup(type) {
			if(type == "manageNo"){
				this.parentId = this.userManageNo;
				this.parentPwd = "";
				this.parentPopupType = "manageNo";
			}else{
				this.parentId = "";
				this.parentPwd = "";
				this.parentPopupType = "memberId";
			}
			this.visible1 = true;
		},
		loadDoc (s_op, s_inJson) {
			var that = this;
			let loadNxClient = (v_s_op, v_s_inJson) => {
				let sUrl = "https://127.0.0.1:16565/?op=";
				if(v_s_op == 'certSelect'){
					sUrl = "https://127.0.0.1:16566/?op=";
				}

				this.$cAxios.post(sUrl+v_s_op, v_s_inJson ,{
					crossDomain: true,
					headers:{
						'Content-type':'application/json; charset=UTF-8',
					},
				}).then((res) =>{
					result(v_s_op, res.data);
				}).catch((err) => {
					if(err.request){ 
						if(err.config.url.indexOf('?op=setup') != -1){
							alert("공인인증서 등록 프로그램 설치가 필요합니다.");
							fnNxDownload();
						}
					}

				}).finally(() => {
				});

			}

			//첫 로드
			loadNxClient(s_op, s_inJson);
			var sendData = new Object();
			let result = (s_op, data) => {
				var errYn = data.errYn;
				if('setup' == s_op){
					if (errYn == 'N') {
						//certImageUrl : 배너이미지
						loadNxClient('certSelect', '{"certImageUrl": "", "nxKeypad": ""}');
					}
				} else if ('certSelect' == s_op) {
					//인증서 선택
					sendData.certNm = data.cert_nm; //인증서명
					sendData.certPubDate = data.pub_dt;
					sendData.certEndDate = data.end_dt;
					sendData.certOrgNm = data.org_nm;//인증기관명 (yessign 등...)
					sendData.certPolicyId = data.oid;
					sendData.certSerialNo = data.sn;
					sendData.password = data.cert_pw;

					callExecute(data);
				} else if ('execute' == s_op) {
					if (errYn == 'N') {
						sendData.signCert = data.DER2PEM;
						sendData.signPri = data.KEY2PEM;

						sendCertData(JSON.stringify(sendData));
					}
				}
				

			}


			let callExecute = (data) => {
				var inJson = new Object();
				inJson.orgCd = 'common';
				inJson.svcCd = 'getCertInfo';
				inJson.appCd = 'ant';// 발급받은 application 코드
				inJson.signCert = data.file1; // der형식의 인증서
				inJson.signPri = data.file2; // 인증서의 키값
				inJson.signPw = data.cert_pw;// 인증서 비밀번호

				loadNxClient('execute', JSON.stringify(inJson));
			}

			let sendCertData = (sendData) => {
				this.$cAxios.post(this.prefix + "/agent/cert/regist", sendData, {
					headers:{
						'Content-Type' : "application/json",
					}
				}).then(function(){
					alert("인증서가 등록되었습니다.");
					that.authInquiry();
				}).catch(()=>{
				}).finally(()=>{
				});

			}

			let fnNxDownload = () => {
				location.href = "/files/ExAdapter_Web_Setup_20200611.exe";
			}

		},
		submitForm(type, val1, val2){
			var url = "";
			if(type == "manageNo"){
				url += "/agent/manageNo/regist";
			}else if(type == "memberId"){
				url += "/agent/memberId/regist";
			}

			let idpwData = new Object();
			idpwData.authId = val1;
			idpwData.password = val2;

			this.$cAxios.post(this.prefix + url, JSON.stringify(idpwData) ,{
				headers:{
					'Content-Type':'application/json'
				},
			}).then(()=>{
				alert("등록되었습니다.");
				this.visible1 = false;
				this.authInquiry();
			}).catch(()=>{
				this.authInquiry();
			});
			
		},
		verifyAuth(){
			var that = this;
			this.$cAxios.post(this.prefix + "/agent/verify")
			.then(()=>{
				alert("검증 완료되었습니다.");
				that.authInquiry();
			})
			.catch((err)=>{
				if(err.response){
					alert(err.response.data.message);
				}
				this.tableData_attr.totalErrorMsg = err.response.data.message;
			});
		},

	},
	created() {
		this.initInfo();

	}
	
}
</script>